const signalR = require("@microsoft/signalr");


let connection = null;

export const state = {
   connectionId: null
}

export const mutations = {
    SET_CONNECTIONID(state, newValue) {
        state.connectionId = newValue;
    },
}
export const getters = {
    signalrConnection(state) {
        return state.connectionId;
    }
}

export const actions = {
    connect({ dispatch, commit }) {
        return new Promise( (resolve) => {
            
            if(connection) {
                connection.stop();
            }
          
            if(localStorage.getItem('access_token')) {
                const uri = process.env.VUE_APP_BASE_URL + "/client-notifications";
                console.log('signlaR trying to connect ' + uri)
                connection = new signalR.HubConnectionBuilder()
                  .withUrl(uri, { accessTokenFactory: () => localStorage.getItem('access_token') })
                  .withAutomaticReconnect()
                  .build();
            
                connection.on("RefreshAccounts", () => {
                  console.log('RefreshAccounts');
                  dispatch('accounts/load', null, { root: true });
                });

                connection.on("PreviewRequestResponse", ({seq, data, error, aicontext} = {}) => {
                  console.log('PreviewRequestResponse', {seq, data, error, aicontext});
                  dispatch('portfolioEdit/respRreview', {seq, data, error}, { root: true });
                  dispatch('chatgpt/previewReady', {aicontext}, { root: true });
                });

                connection.on("RefreshRiskProfiles", () => {
                    console.log('RefreshRiskProfiles');
                    dispatch('riskprofiles/load', null, { root: true });
                });
            
                connection.start()
                  .then(() => {
                    console.log('signlaR connected!', connection.connection.connectionId)
                    commit('SET_CONNECTIONID', connection.connection.connectionId);
                })
                .catch(console.error);

                connection.onreconnected((connectionId) => {
                    console.log('signlaR onreconnected!', connectionId)
                    commit('SET_CONNECTIONID', connectionId);
                });

                connection.on("SendMessage", data => {
                    console.log('SendMessage', data);
                    commit('portfolios/END_REQUEST', { resp: data}, { root: true });
                    commit('execution/END_REQUEST', { resp: data}, { root: true });
                });

                connection.onclose(async () => {
                    commit('SET_CONNECTIONID', null);
                });
              
            }
            resolve();
       });
   },
   disconnect() {
        return new Promise( (resolve) => {
            if(connection) {
               connection.stop();
            }
            connection = null;
            resolve();
        });
    },
}
