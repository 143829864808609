import http from '@/oauth-client'
import { uuid } from 'vue-uuid';
import moment from 'moment'
import { camelCase } from 'lodash';
import axios from 'axios';

let __timer;

const interactiveBrokerClient = axios.create({
    baseURL: 'http://localhost:7000',
    headers: {
        "Content-Type": "application/json",
        // anything you want to add to the headers
    }
});

const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(v => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        }),
        {},
      );
    }
    return obj;
  };

const UPLOAD_TRANSACTIONS = 'UPLOAD_TRANSACTIONS';
const VALIDATE_TRANSACTIONS = 'VALIDATE_TRANSACTIONS';
const EXECUTION_RUNTIME_INIT_REQ_NAME = 'EXECUTION_RUNTIME_INIT';
const SAVE_EXECUTION_SETTINGS_REQ_NAME = 'SAVE_EXECUTION_SETTINGS';
const LOAD_EXECUTION_SETTINGS_REQ_NAME = 'LOAD_EXECUTION_SETTINGS';
const SAVE_USER_SETTINGS_REQ_NAME = 'SAVE_USER_SETTINGS';
const LOAD_USER_SETTINGS_REQ_NAME = 'LOAD_USER_SETTINGS';
const LOAD_LIVE_INDICIES_REQ_NAME = 'LOAD_LIVE_INDICIES';
const LOAD_LIVE_ITEM_STAT_REQ_NAME = 'LOAD_LIVE_ITEM_STAT';
const LOAD_LIVE_ITEM_REBALANCE_REQ_NAME = 'LOAD_LIVE_ITEM_REBALANCE';
const LOAD_LIVE_ITEM_PORTFOLIO_POSITIONS_REQ_NAME = 'LOAD_LIVE_ITEM_PORTFOLIO_POSITIONS';
const LOAD_LIVE_ITEM_PORTFOLIO_TRANSACTIONS_REQ_NAME = 'LOAD_LIVE_ITEM_PORTFOLIO_TRANSACTIONS';
const MAKE_OPTIMIZATION_REQ_NAME = "MAKE_OPTIMIZATION";


const TIMEOUT = 120000;

const getDefaultState = () => {
    return {
        interactiveBrokers:{
            connected: false
        },
        uploadTransactionsFromTime: null,
        liveIndicies: [],
        isBackendAlive: false,
        requests: [],
        failedRequests: [],
        runtime: {
            orders: []
        },
        item: {
            id: null,
           
            currencyName: 'USD',
            name: '',
            chartData: [],
            weights: [],
           
            totalReturn: null,
            ytd: null,
            mtd: null,
            dtd: null,

            reBalanceCloseoutTrades: false,
            reBalance: [],
            positions: [],
            userSettings: null,
            executionSettings: null,
            transactions: {
                items: [],
                total: 0,
                reqSize: 100
            }
        }
    };
};

export const state = getDefaultState();

export const mutations = {
    SET_ORDER_PROCESSING(state, {id, value}) {
        const items = [...state.runtime.orders];
        const o = items.find(x => x.id === id);
        o.processing = value;
        state.runtime.orders = items;
    },
    SET_UPLOAD_TRANSACTIONS_FROM_TIME(state, newValue) {
        state.uploadTransactionsFromTime = newValue;
    },
    PAPER_TRADE_UPDATE(state) {
        const items = [...state.runtime.orders.filter(x => x.selected)];
        items.forEach(x => x.orderStatus = 'Filled');
    },
    HANDLE_INTERACTIVE_BROKER_POSITIONS_UPDATE(state, {brokerPositions}) {
        const pos = JSON.parse(JSON.stringify(state.item.positions));
        pos.forEach(p=>{
            let ticker = p.ticker;
            if ( ticker.includes(":") )
            {
                var nameList = ticker.split(":");
                ticker = nameList[0];
            }
            const brokerPos = brokerPositions.find(x => x.instrument === ticker);
            if(brokerPos) {
                p.brokerPosition = brokerPos.position;
               // console.log('found position', brokerPos);
            } 
        });
       // console.log('HANDLE_INTERACTIVE_BROKER_POSITIONS_UPDATE', {pos, brokerPositions});
        state.item.positions = pos;
    },
    HANDLE_INTERACTIVE_BROKER_UPDATE(state, data) {
        const items = [...state.runtime.orders];
        const order = items.find(x => x.id === data.clOrderID);
        
        if(order) {
            const index = items.indexOf(order);
            if(index>= 0) {
                
                const order = {...items[index]};
                order.orderStatus = data.status;
                order.filled = data.cumQty;
                order.remaining = data.leavesQty;
                items[index] = order;

                state.runtime = { ...state.runtime, orders: items};
            }
        }
    },
    SET_INTERACTIVE_BROKERS_CONNECTED(state, newValue) {
        const copy = {...state.interactiveBrokers};
        if(copy.connected !== true && newValue) {
            state.requests.forEach(req => {
                if(req.type === UPLOAD_TRANSACTIONS) {
                    if(req.reject) {
                        req.reject();
                    }
                }
            });
            state.requests = state.requests.filter(req => req.type !== UPLOAD_TRANSACTIONS);
        }
        copy.connected = newValue;
        state.interactiveBrokers = copy;
    },
    SET_ORDER_SIZE(state, {index, size}) {
        if(size === '' ||  size == null || size == undefined || Number(size) === Number.NaN) {
            state.runtime.orders[index].orderQuantity = null;
        } else {
            state.runtime.orders[index].orderQuantity = Number(size)
        }
    },
    SET_ORDER_PRICE(state, {index, price}) {
        state.runtime.orders[index].price = price ? parseFloat(price) : 0;
    },
    SET_ORDER_PARAMETERS(state, {index, parameters}) {
        state.runtime.orders[index].orderParameters = parameters;
    },
    SET_ORDER_TYPE(state, {index, orderType}) {
        state.runtime.orders[index].orderType = orderType;
        const settings = state.runtime.orders[index].executionSettings.orderTypes.find(x => x.orderType === orderType);
        state.runtime.orders[index].orderParameters = settings.orderParameters;
    },
    SELECT_ALL_RUNTIME_ORDERS(state) {
        state.runtime.orders = state.runtime.orders.map(x =>{
            return {...x, selected: true };
        });
    },
    UNSELECT_ALL_RUNTIME_ORDERS(state) {
        state.runtime.orders = state.runtime.orders.map(x =>{
            return {...x, selected: false };
        });
    },
    SET_ORDER_SELECTED(state, {index, selected}) {
        state.runtime.orders[index].selected = selected;
    },
    SET_REBALANCE_CLOSEOUT_TRADES(state, newValue) {
        state.item.reBalanceCloseoutTrades = newValue;
    },
    SET_ITEM_ID(state, newValue) {
        state.item.id = newValue;
    },
    SET_CURRENCY_NAME(state, newValue) {
        state.item.currencyName = newValue;
    },
    SET_IS_BACKEND_ALIVE(state, newValue) {
        state.isBackendAlive = newValue
    },
    SET_LIVE_INDICIES_LOADING(state, newValue) {
        state.liveIndicies.loading = newValue
    },
    BEGIN_REQUEST(state, { req } = {}) {
        state.requests = [...state.requests, req];
    },
    END_REQUEST(state, { resp } = {}) {
        const reqId = resp.reqId || resp.ReqId;
        const req = state.requests.find(x => x.id == reqId );
     
        if(!req) return;

      //  console.log('END_REQUEST', {req, resp });

        state.requests = [...state.requests.filter(x => x.id != reqId)];
      
        if(!resp.failed) {
            if(req.type === EXECUTION_RUNTIME_INIT_REQ_NAME) {
                HANDLE_INIT_REQUEST(state, { payload: resp.payload || resp.Payload } );
            }else if(req.type === LOAD_LIVE_INDICIES_REQ_NAME) {
                state.liveIndicies = (resp.payload || resp.Payload).map(x => {
                    return { name: x.Name, id: x.id || x.Id };
                });
            } else if(req.type === LOAD_LIVE_ITEM_STAT_REQ_NAME) {
                const { Name, ChartData, Weights, TotalReturn, YTD, MTD, DTD } = resp.payload || resp.Payload;
                state.item.name = Name;
                state.item.chartData = ChartData.map(x => {
                    return {
                        timeStamp: moment(x.dateTime || x.DateTime).unix(),
                        dateTime: x.dateTime || x.DateTime,
                        value: x.value || x.Value
                    };
                });
                state.item.weights = Weights.map(x => {
                    return {
                        productName: x.ProductName,
                        ticker: x.Ticker,
                        weight: x.weight || x.Weight
                    };
                });
                state.item.totalReturn = TotalReturn;
                state.item.ytd = YTD;
                state.item.mtd = MTD;
                state.item.dtd = DTD;
            } else if(req.type === LOAD_LIVE_ITEM_REBALANCE_REQ_NAME) {
                const reBalance = resp.payload || resp.Payload;
                state.item.reBalance = reBalance.map(x => {
                    return { productName: x.ProductName, ticker: x.Ticker, currentSize: x.CurrentSize, difference: x.Difference, proposedSize: x.ProposedSize};
                });
            } else if(req.type === LOAD_EXECUTION_SETTINGS_REQ_NAME) {
                
                const executionSettings =  resp.payload || resp.Payload;
               
                try{
                    const newItem = {...state.item, executionSettings: executionSettings.OrderSettings.map(x => {
                            
                            var o = {
                                displayName: x.DisplayName,
                                productName: x.ProductName,
                                ticker: x.Ticker,
                               // defaultOrderType: ot ? { orderType: ot.OrderType, orderParameters: ot.OrderParameters } : null,
                                orderTypes: x.OrderTypes.map(p => {
                                    return { orderType: p.OrderType, orderParameters: p.OrderParameters }
                                })
                            };
                            o.defaultOrderType = (x.DefaultOrderType || {}).OrderType;
                            return o;
                        })
                    };
                    state.item = newItem;
                }catch(e) {
                  console.error(e);
                }
               
            } else  if(req.type === LOAD_USER_SETTINGS_REQ_NAME) {
                const userSettings =  resp.payload || resp.Payload;
                state.item = {...state.item, userSettings:{
                    providerName: userSettings.ProviderName,
                    name: userSettings.Name,
                    debugLevel: userSettings.DebugLevel,
                    autoExecuteTime: userSettings.AutoExecuteTime,
                    autoSnap: userSettings.AutoSnap,
                    hasExecuted: userSettings.HasExecuted,
                    aus: userSettings.AUS,
                    ausCurrency: userSettings.AUSCurrency,
                    gearing: userSettings.Gearing,
                    startDate: userSettings.StartDate ? moment(userSettings.StartDate).format('YYYY/MM/DD') : '',
                    annualFixFee: userSettings.AnnualFixFee,
                    annualPerformanceFee: userSettings.AnnualPerformanceFee,
                    userName: userSettings.UserName,
                    password: userSettings.Password,
                    email: userSettings.Email,
                    broker: userSettings.Broker,
                    defaultOrderType: userSettings.DefaultOrderType,
                    defaultOrderSettings: userSettings.DefaultOrderSettings,
                    options: {
                        brokers: userSettings.Options.Brokers.map(b => {
                            return {
                                brokerName: b.BrokerName,
                                defaultOrderType: b.DefaultOrderType,
                                orderTypes: b.OrderTypes.map(o => {
                                    return {
                                        orderType: o.OrderType,
                                        settings: o.Settings.map(s => {
                                            return {
                                                name: s.Name,
                                                value: s.Value
                                            }
                                        })
                                    }
                                }),
                            }
                        })
                    }
                }};
            } else if(req.type === LOAD_LIVE_ITEM_PORTFOLIO_POSITIONS_REQ_NAME) {
                const payload = resp.payload || resp.Payload;
                state.item.positions = payload.map(x => {
                    return {  displayName: x.DisplayName, productName: x.ProductName, ticker: x.Ticker, size: x.Size, brokerPosition: x.BrokerPosition, pl: x.PL, warning: x.Warning };
                });
            } else if(req.type === LOAD_LIVE_ITEM_PORTFOLIO_TRANSACTIONS_REQ_NAME) {
                const payload = resp.payload || resp.Payload;
                const { Total, Items } = payload;
                state.item.transactions.total = Total;
                const items = Items.map(x => {
                    return { displayName: x.DisplayName, productName: x.ProductName, ticker: x.Ticker, direction: x.Direction, size: x.Size, price: x.Price, timestamp: x.Timestamp };
                });

                state.item.transactions.items = req.more ? [...state.item.transactions.items, ...items] : items;
            }
            if(req.resolve) {
                req.resolve(resp);
            }
        } else {
            state.failedRequests = [...state.failedRequests,{...req, errorMessage: resp.errorMessage || resp.ErrorMessage}];
            if(req.reject) {
                req.reject();
            }
        }
    }
}

export const getters = {
    uploadTransactionsFromTime(state) {
        return state.uploadTransactionsFromTime;
    },
    interactiveBrokers(state) {
        return state.interactiveBrokers;
    },
    item(state) {
        return state.item;
    },
    isBackendAlive(state) {
        return state.isBackendAlive;
    },
    liveIndicies(state) {
        return state.liveIndicies;
    },
    loading(state) {
        return state.requests.length > 0;
    },
    runtimeOrders(state) {
        return state.runtime.orders || [];
    }
}

function HANDLE_INIT_REQUEST(state, {payload} = {}) {

    var orders = camelizeKeys(payload);
    orders.forEach(order => {
        order.selected = true;
        order.filled = 0;
        order.remaining = 0;
    });
    //console.log('HANDLE_INIT_REQUEST', orders);
    state.runtime.orders = orders;
}


export const actions = {
    async cancellAllInteractiveBrokersOrders({ dispatch, commit }) {
        try {
            await interactiveBrokerClient.get('/execution/cancelall');
            await dispatch('updateInteractiveBrokersOrders');
        } catch(e) {
            console.error(e);
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
        }
    },
    async checkConnectStatusOfInteractiveBrokers ({ dispatch, commit }) {
        try {
            const resp = await interactiveBrokerClient.get('/execution/connectionstatus');
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', resp.data.connected);
            dispatch('updateInteractiveBrokersOrders');
        } catch(e) {
            console.error(e);
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
        }
    },
    async updateInteractiveBrokersOrders ({dispatch, commit, state}) {
        if(__timer){
            window.clearTimeout(__timer);
        }
        
        try {
            if(state.interactiveBrokers.connected) {
                const response = await interactiveBrokerClient.get('/execution/orders');
                response.data.forEach(o => {
                    commit('HANDLE_INTERACTIVE_BROKER_UPDATE', o);
                });
            }
        
            __timer = window.setTimeout(() => {
                dispatch('updateInteractiveBrokersOrders');
            }, 1000);   

        } catch(e) {
            console.error(e);
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
        }
    },
    uploadTransactionsFromFile( {dispatch, state }, txList) {
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', { payload: { id:  state.item.id, items: txList }, resolve, reject, type: UPLOAD_TRANSACTIONS });
        });
    },
    validateTransactionsFromFile( {dispatch, state }, txList) {
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', { payload: { id:  state.item.id, items: txList }, resolve, reject, type: VALIDATE_TRANSACTIONS });
        });
    },
    uploadTransactionsFromInteractiveBrokers ({ dispatch, state, commit }) {
        return new Promise( (resolve, reject) => {
            let param = '';
            if(state.uploadTransactionsFromTime) {
                const m = state.uploadTransactionsFromTime;
                param = m;
                param = '?from=' + m;
            }
            interactiveBrokerClient.get('/execution/transactions' + param).then((response) => {
                const orders = state.runtime.orders.map(o => {
                    let ticker = o.ticker;
                    if ( ticker.includes(":") )
                    {
                        var nameList = ticker.split(":");
                        ticker = nameList[0];
                    }
                    return {
                        ticker,
                        productName: o.productName
                    };
                })
                const backendTransactions = response.data.map(tx => {
                    const o = orders.find(o => o.ticker === tx.instrument);
                    return {
                        timeStamp: moment(tx.timeStampStr, 'YYYYMMDD hh:mm:ss').toDate(),
                        execId : tx.execID,
                        orderId: tx.orderID,
                        productName: o ? o.productName : null,
                        ticker: tx.instrument,
                        qty: tx.qty,
                        price: tx.price
                    };
                }).filter(x => x.productName);

                dispatch('makeReq', { payload: { id:  state.item.id, items: backendTransactions }, resolve, reject, type: UPLOAD_TRANSACTIONS });
            }).catch((error)=>{
                commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
                reject(error);
            });
            
        });
    },
    async cancelOrderInteractiveBrokers ({commit}, {clOrderID}) {
        try {
            commit('SET_ORDER_PROCESSING', {id: clOrderID, value: true});
            await interactiveBrokerClient.post('/execution/cancel', {clOrderID});
            window.setTimeout(() => {
                commit('SET_ORDER_PROCESSING', {id: clOrderID, value: false});
            }, 1000);
            
        } catch(e) {
            console.error(e);
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
        } 
    },
    async executeOrderInteractiveBrokers ({commit}, dto) {
        try {
            const response = await interactiveBrokerClient.post('/execution/new', dto);
            commit('HANDLE_INTERACTIVE_BROKER_UPDATE', response.data);
        } catch(e) {
            console.error(e);
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
        } 
    },
    async getBrokerPositionsFromInteractiveBrokers({commit}) {
        try {
            const resp = await interactiveBrokerClient.get('/execution/positions');
            commit('HANDLE_INTERACTIVE_BROKER_POSITIONS_UPDATE', {brokerPositions:resp.data});
        } catch(e) {
            console.error(e);
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
        } 
    },
    async connectToInteractiveBrokers ({ commit, state, dispatch }) {
        // try {
        //     await interactiveBrokerClient.get('/execution/connect');
        //     commit('SET_INTERACTIVE_BROKERS_CONNECTED', true);
        //     return;
        // } catch(e) {
        //     console.error(e);
        //     commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
        // }
        
        try {
            await dispatch('loadUserSettings')
            await interactiveBrokerClient.post('/execution/connect', state.item.userSettings);
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', true);
            return;
        } catch(e) {
            console.error(e);
            commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
        }
    },
    async disconnectFromInteractiveBrokers ({ commit }) {
        await interactiveBrokerClient.get('/execution/disconnect');
        commit('SET_INTERACTIVE_BROKERS_CONNECTED', false);
    },
    async isBackendAlive({ commit }) {
        try { 
            const response = await http.get('api/backend/alive');
            commit('SET_IS_BACKEND_ALIVE',  response.data);
        } catch(e) {
            console.log(e);
            commit('SET_IS_BACKEND_ALIVE',  false);
        }
    },
    async makeReq({ commit, rootState }, { payload, type, resolve, reject } = {}) {
        const req = { 
            id: uuid.v1(),
            resolve, 
            reject,
            clientId: rootState.signalr.connectionId,
            type: type,
            payload: payload,
            userName: rootState.auth.currentUser.UserName,
            userId: rootState.auth.currentUser.IdentityId,
        };
        commit('BEGIN_REQUEST',  { req });
        window.setTimeout(() => {
            commit('END_REQUEST',  { resp: { reqId: req.id, failed: true, errorMessage: "Timeout reached" } });
        }, TIMEOUT);

        await http.post('api/backend/messages/out', req);

       // commit('END_REQUEST', { resp: {...d, ReqId: req.id}});
    },
    async changeCurrency({dispatch, commit}, {currencyName } = {}) {
        commit('SET_CURRENCY_NAME', currencyName);
        await dispatch('loadItem');
    },
    async changeReBalanceCloseoutTrades({dispatch, commit}, newValue) {
        console.log('store changeReBalanceCloseoutTrades', newValue);
        commit('SET_REBALANCE_CLOSEOUT_TRADES', newValue);
        await dispatch('makeReq', {payload: { id:  state.item.id, reBalanceCloseoutTrades: newValue }, type: LOAD_LIVE_ITEM_REBALANCE_REQ_NAME});
    },
    saveExecutionSettings({ dispatch, state }, { value } = {}) {
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', { payload: { id:  state.item.id, value }, resolve, reject, type: SAVE_EXECUTION_SETTINGS_REQ_NAME });
        });
    },
    saveUserSettings({ dispatch, state }, { value } = {}) {
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', { payload: { id:  state.item.id, value }, resolve, reject, type: SAVE_USER_SETTINGS_REQ_NAME });
        });
    },
    saveUserSettingsById({ dispatch }, { id, value } = {}) {
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', { payload: { id:  id, value }, resolve, reject, type: SAVE_USER_SETTINGS_REQ_NAME });
        });
    },
    loadExecutionSettings({ dispatch, state }) {
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', {payload: { id:  state.item.id, }, resolve, reject, type: LOAD_EXECUTION_SETTINGS_REQ_NAME});
        });
    },
    loadUserSettings({ dispatch, state }) {
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', {payload: { id:  state.item.id, }, resolve, reject, type: LOAD_USER_SETTINGS_REQ_NAME});
        });
    },
    async loadPositions({ dispatch, state }) {
        await dispatch('makeReq', {payload: { id: state.item.id }, type: LOAD_LIVE_ITEM_PORTFOLIO_POSITIONS_REQ_NAME});
    },
    async loadTransactions({ dispatch, state }) {
        await dispatch('makeReq', {payload: { id: state.item.id, take: state.item.transactions.reqSize, skip: state.item.transactions.items.length }, type: LOAD_LIVE_ITEM_PORTFOLIO_TRANSACTIONS_REQ_NAME});
    },
    async loadMoreItemTransactions({ dispatch, state }) {
        await dispatch('makeReq', {payload: { more: true, id:  state.item.id, take: state.item.transactions.reqSize, skip: state.item.transactions.items.length }, type: LOAD_LIVE_ITEM_PORTFOLIO_TRANSACTIONS_REQ_NAME});
    },
    async loadItem({ dispatch, state }) {
       await dispatch('makeReq', {payload: { id: state.item.id, currencyName: state.item.currencyName }, type: LOAD_LIVE_ITEM_STAT_REQ_NAME});
       await dispatch('makeReq', {payload: { id: state.item.id, reBalanceCloseoutTrades: state.item.reBalanceCloseoutTrades }, type: LOAD_LIVE_ITEM_REBALANCE_REQ_NAME});
    },
    async loadLiveIndicies({ dispatch }) {
        await dispatch('makeReq', {payload: null, type: LOAD_LIVE_INDICIES_REQ_NAME});
    },
    
    async makeOptimization({ dispatch }, input ) {
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', { payload: {  json: input }, resolve, reject, type: MAKE_OPTIMIZATION_REQ_NAME });
        });
    },
    async initRuntime({ dispatch }, {  refresh, taxLossHarvest, taxLossHarvestData } ) {
        dispatch('updateInteractiveBrokersOrders');
        dispatch('checkConnectStatusOfInteractiveBrokers');
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', { payload: {  id: state.item.id, refresh, taxLossHarvest, taxLossHarvestData }, resolve, reject, type: EXECUTION_RUNTIME_INIT_REQ_NAME });
        });
    },
    paperTrade({commit, dispatch, state}) {
        commit('PAPER_TRADE_UPDATE');
        const orders = state.runtime.orders.filter(x => x.selected);
        let backendTransactions = [];
        orders.forEach(o => {
            backendTransactions.push({
                timeStamp: moment().toDate(),
                execId : uuid.v1(),
                orderId: o.id,
                productName: o.productName,
                ticker: o.ticker,
                qty: o.orderSide === 'Sell' ? (Math.abs(o.orderQuantity) * -1) : Math.abs(o.orderQuantity),
                price: o.price
            });
        });
        return new Promise( (resolve, reject) => {
            dispatch('makeReq', { payload: { id:  state.item.id, items: backendTransactions }, resolve, reject, type: UPLOAD_TRANSACTIONS });
        });
    }
}